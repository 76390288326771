import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {Autocomplete} from '@material-ui/lab';
import {CircularProgress, FormHelperText, InputAdornment, MenuItem} from '@material-ui/core';
import {EMPTY_INPUT, removeValueFromFormError, setNewValueInFormError} from '../../store/reducer/formError';
import {append, count, isEmpty, isNil} from 'ramda';
import {connect, useDispatch} from 'react-redux';
import {grey} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/styles';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {useTranslation} from 'react-i18next';

/**
 * Render a checkbox.
 *
 * @type {string}
 */
export const CHECKBOX_TYPE = 'checkbox';

/**
 * Render a radio choice.
 *
 * @type {string}
 */
export const RADIO_TYPE = 'radio';

/**
 * Render an input number.
 *
 * @type {string}
 */
export const NUMBER_TYPE = 'number';
export const FLOAT_TYPE = 'float';

/**
 * Render an input text.
 *
 * @type {string}
 */
export const TEXT_TYPE = 'text';

/**
 * Render an input date.
 *
 * @type {string}
 */
export const DATE_TYPE = 'date';

/**
 * Render an input select.
 * The input options are an "auto-provided" (via the store) controlled list.
 *
 * @type {string}
 */
export const CONTROLLED_LIST_SELECT_TYPE = 'controlledListSelect';

/**
 * Render an input select.
 * The input options are an "auto-provided" (via the store) controlled list.
 * This combines the TEXT_TYPE and CONTROLLED_LIST_SELECT_TYPE, there are choices but you can put anything in the input.
 *
 * @type {string}
 */
export const CONTROLLED_LIST_AUTOCOMPLETE_TYPE = 'controlledListAutocomplete';

const useStyles = makeStyles(() => ({
    inputRoot: {
        '&$disabled': {
            backgroundColor: grey[300],
        },
    },
    disabled: {},
}));

/**
 * This component allows to render different kind of inputs depending on the type props.
 *
 * @param label
 * @param field is the name in string of the field which will be used by the input.
 * @param type
 * @param value
 * @param min is usable only with a NUMBER_TYPE.
 * @param max is usable only with a NUMBER_TYPE.
 * @param required
 * @param specificValidator is a function which must return conditionally true.
 * This true value will disable the input.
 *
 * @param choices is a list of possible choices usable only with RADIO_TYPE.
 * @param onChangeAction is a function which override the default onChangeAction (set down below).
 * @param disabled
 *
 * @param split divide by two a couple of two fields which following itself.
 * The first field has the value 1 in split and the second 2.
 *
 * @param inRow display the input in row by disabled the container props of the parent Grid.
 * This props don't work with the split props.
 *
 * Following params are embedded from the store.
 * @param controlledLists The field props permits to know which controlled list the input must choose in his options props.
 * @param isLoadingControlledLists
 * @param controlledListsLoaded
 * @param formError
 * @param adornment
 */
const FormFieldRender = ({
    label,
    field,
    type,
    value,
    min = Infinity,
    max = Infinity,
    required = false,
    specificValidator = () => {},
    choices = [],
    onChangeAction = null,
    disabled = false,
    split = 0,
    inRow = false,
    controlledLists,
    isLoadingControlledLists,
    controlledListsLoaded,
    formError,
    adornment= null,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    let defaultOnChangeAction = (e) => {
        dispatch(setNewValueInVehicle({
            property: field,
            newValue: e.target.value,
        }))
    }

    let customizableControlledLists = () => {
        return controlledLists[field];
    }

    /**
     * Used only when the controlledLists didn't have results.
     *
     * Return a set of 2 values :
     * - One which allow to display in the select input the value of the object.
     * - One to display in the select option the value 'no data'.
     */
    let noSelectResult = (value) => {
        return isEmpty(value) || isNil(value)
            ? [
                {label: t('noData')},
            ]
            : [
                {label: value, value: value},
                {label: t('noData')},
            ]
        ;
    }

    const handleError = (value) => {
        if (!isNil(specificValidator(value))) {
            dispatch(setNewValueInFormError({property: field, newValue: specificValidator(value)}))
        }

        if (required) {
            if (isEmpty(value)) {
                dispatch(setNewValueInFormError({property: field, newValue: t(EMPTY_INPUT)}))
            } else {
                if (field in formError) {
                    dispatch(removeValueFromFormError(field))
                }
            }
        }
    }
    let inputprops
    switch (type) {
    case CHECKBOX_TYPE:
        defaultOnChangeAction = (e) => {
            dispatch(setNewValueInVehicle({
                property: field,
                newValue: e.target.checked,
            }))
        }

        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
                style={{display: 'flex'}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value}
                            onChange={(e) => {
                                onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                            }}
                        />
                    }
                    label={label?label:''}
                    disabled={disabled}
                    style={{alignSelf: 'center'}}
                />
            </Grid>
        );
    case RADIO_TYPE:
        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
                style={{marginTop: '.5rem'}}
            >
                <Grid item>
                    {
                        label ?
                            <Typography
                                variant="body1"
                                component="span"
                                style={{marginRight: '.5rem'}}
                            >
                                <b>{label}</b>
                            </Typography>
                        : null
                    }
                    <RadioGroup
                        row
                        value={value}
                        onChange={(e) => {
                            onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                        }}
                    >
                        {choices.map((val, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={val.value}
                                    label={val.label}
                                    control={<Radio color="primary"/>}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </RadioGroup>
                </Grid>
            </Grid>
        );
    case NUMBER_TYPE :
    case FLOAT_TYPE:
        defaultOnChangeAction = (e) => {
            dispatch(setNewValueInVehicle({
                property: field,
                newValue: type === NUMBER_TYPE ? Number.parseInt(e.target.value) : Number.parseFloat(e.target.value).toString(10),
            }))
        }

        inputprops=
            {
                classes: {
                    root: classes.inputRoot,
                    disabled: classes.disabled,
                }
            ,
                inputProps: {
                    min: min,
                    max: max,
                }
            }
        if (adornment) {
            inputprops['endAdornment'] = <InputAdornment position="end">{adornment}</InputAdornment>
        }

        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
            >
                <TextField
                    label={label}
                    type="number"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    required={required}
                    error={(field in formError)}
                    value={value}
                    style={!inRow ? split === 1 ? {padding: '0 .4rem 0 0'} : null : null}
                    onChange={(e) => {
                        onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                    }}
                    onBlur={(e) => handleError(e.target.value)}
                    disabled={disabled}
                    fullWidth
                    InputProps={inputprops}
                    InputLabelProps={{ shrink: true }}
                />
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <FormHelperText style={{marginRight: '2.5rem', zIndex: 1, marginTop: '-2.1rem', color: 'red'}}>
                            {formError[field]}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        );
    case TEXT_TYPE:
        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
            >
                <TextField
                    label={label}
                    type="text"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    required={required}
                    error={(field in formError)}
                    value={value}
                    style={!inRow ? split === 1 ? {padding: '0 .4rem 0 0'} : null : null}
                    onChange={(e) => {
                        onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                    }}
                    onBlur={(e) => handleError(e.target.value)}
                    disabled={disabled}
                    fullWidth
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <FormHelperText style={{marginRight: '2.5rem', zIndex: 1, marginTop: '-2.1rem', color: 'red'}}>
                            {formError[field]}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        );
    case DATE_TYPE:
        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
            >
                <TextField
                    label={label}
                    type="date"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    required={required}
                    error={(field in formError)}
                    value={value}
                    style={!inRow ? split === 1 ? {padding: '0 .4rem 0 0'} : null : null}
                    onChange={(e) => {
                        onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                    }}
                    onBlur={(e) => handleError(e.target.value)}
                    disabled={disabled}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                        },
                    }}
                />
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <FormHelperText style={{marginRight: '2.5rem', zIndex: 1, marginTop: '-2.1rem', color: 'red'}}>
                            {formError[field]}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        );
    case CONTROLLED_LIST_SELECT_TYPE:
        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
            >
                <TextField
                    label={label}
                    select
                    variant="outlined"
                    size="small"
                    margin="dense"
                    required={required}
                    error={(field in formError)}
                    value={value}
                    style={!inRow ? split === 1 ? {padding: '0 .4rem 0 0'} : null : null}
                    onChange={(e) => {
                        onChangeAction ? onChangeAction(e) : defaultOnChangeAction(e);
                    }}
                    onBlur={(e) => {
                        handleError(e.target.value);
                    }}
                    fullWidth
                    disabled={disabled}
                    SelectProps={{
                        classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    {isLoadingControlledLists
                        ? <MenuItem>{t('loading')}</MenuItem>
                        : controlledListsLoaded
                            ? isEmpty(customizableControlledLists())
                                // Controlled list could be empty but loaded when the request went good but the api doesn't return value for the field.
                                ? noSelectResult(value).map((item, key) =>
                                    <MenuItem
                                        key={key}
                                        value={item.value}
                                        style={item.value ? {display: 'none'} : null}
                                        disabled={true}
                                    >
                                        {item.label}
                                    </MenuItem>
                                )
                                : customizableControlledLists().map((item, index) =>
                                    <MenuItem key={index} value={item['value']}>
                                        {item['label']}
                                    </MenuItem>
                                )
                            // Whether the controlled isn't loaded because the request went wrong, the list is empty for the field.
                            : noSelectResult(value).map((item, key) =>
                                <MenuItem
                                    key={key}
                                    value={item.value}
                                    style={item.value ? {display: 'none'} : null}
                                    disabled={true}
                                >
                                    {item.label}
                                </MenuItem>
                            )
                    }
                </TextField>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <FormHelperText style={{marginRight: '2.5rem', zIndex: 1, marginTop: '-2.1rem', color: 'red'}}>
                            {/* If for some reason the input is in error during fetching controlledLists, don't display the error ... */}
                            {!isLoadingControlledLists ? formError[field] : null}
                        </FormHelperText>
                        {/* ... display instead the CircularProgress. */}
                        {isLoadingControlledLists ? <CircularProgress style={{marginRight: '2.5rem', zIndex: 1}} size={20}/> : null}
                    </Grid>
                </Grid>
            </Grid>
        );
    case CONTROLLED_LIST_AUTOCOMPLETE_TYPE:
        customizableControlledLists = () => {
            // Because controlled Autocomplete forces to have all choices in the options, we must insert the custom input value in the options.
            if (controlledListsLoaded && value && count(option => option.value === value, controlledLists[field]) === 0) {
                return append({label: value, value: value}, controlledLists[field]).map(item => {
                    return item.value;
                });
            } else {
                return controlledLists[field].map(item => {
                    return item.value;
                });
            }
        }
        noSelectResult = () => [t('noData')]

        return (
            <Grid
                container={!inRow}
                item
                xs={!inRow ? split ? 6 : 12 : null}
            >
                <Autocomplete
                    freeSolo
                    value={value}
                    // Force to use onBlur event because onChange event is weirdly not triggered when user writes a non-existence choice in the input.
                    onBlur={(e) => {
                        onChangeAction
                            ? onChangeAction(e)
                            : defaultOnChangeAction(e)
                        ;
                        handleError(e.target.value);
                    }}
                    getOptionLabel={option => {
                        return option ? option : '';
                    }}
                    options={controlledListsLoaded
                        ? isEmpty(customizableControlledLists())
                            ? noSelectResult()
                            : customizableControlledLists()
                        : noSelectResult()
                    }
                    getOptionDisabled={controlledListsLoaded
                        ? isEmpty(customizableControlledLists())
                            ? () => true
                            : null
                        : () => true
                    }
                    loading={isLoadingControlledLists}
                    disabled={disabled}
                    fullWidth
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            size="small"
                            margin="dense"
                            required={required}
                            error={(field in formError)}
                            style={!inRow ? split === 1 ? {padding: '0 .4rem 0 0'} : null : null}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                },
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoadingControlledLists ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    }
                />
            </Grid>
        )
    default:
        return <></>;
    }
}

FormFieldRender.propTypes = {
    label: PropTypes.string,
    field: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    required: PropTypes.bool,
    specificValidator: PropTypes.func,
    choices: PropTypes.array,
    onChangeAction: PropTypes.func,
    disabled: PropTypes.bool,
    split: PropTypes.number,
    inRow: PropTypes.bool,
    controlledLists: PropTypes.object,
    isLoadingControlledLists: PropTypes.bool,
    controlledListsLoaded: PropTypes.bool,
    formError: PropTypes.object,
    adornment: PropTypes.string,
}

export default connect(state => ({
    formError: state.formErrorReducer.formError,
    controlledLists: state.controlledListReducer.controlledLists,
    controlledListsLoaded: state.controlledListReducer.loaded,
    isLoadingControlledLists: state.controlledListReducer.isLoading,
}))(FormFieldRender);