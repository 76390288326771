import i18n from 'i18next';
import {
    CHECKBOX_TYPE,
    CONTROLLED_LIST_AUTOCOMPLETE_TYPE,
    CONTROLLED_LIST_SELECT_TYPE,
    DATE_TYPE,
    FLOAT_TYPE,
    NUMBER_TYPE,
    TEXT_TYPE,
} from '../../components/layout/FormFieldRender';
import {INVALID_INPUT} from '../../store/reducer/formError';
import {flatten} from 'ramda';
import {useSelector} from "react-redux";
import {vehicleModel} from "../../store/reducer/vehicle";

export const firstSectionOfGeneralInformation = () => {
    return [
        {
            label: i18n.t('registrationNumber'),
            field: 'registrationNumber',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('vendor'),
            field: 'vendor',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('controlledAt'),
            field: 'controlledAt',
            type: TEXT_TYPE,
            disabled: true,
        },
    ]
}

export const secondSectionOfGeneralInformation = () => {
    return [
        {
            label: i18n.t('saleDate'),
            field: 'saleDate',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('parcName'),
            field: 'parcName',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('controlledBy'),
            field: 'controlledBy',
            type: TEXT_TYPE,
            disabled: true,
        },
    ]
}

export const thirdSectionOfGeneralInformation = () => {
    return [
        {
            label: i18n.t('orderNumber'),
            field: 'orderNumber',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('complementOrderNumber'),
            field: 'complementOrderNumber',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('typology'),
            field: 'typologieLabel',
            type: TEXT_TYPE,
            disabled: true,
        },
    ]
}

export const fourthSectionOfGeneralInformation = () => {
    return [
        {
            label: i18n.t('transporter'),
            field: 'transporter',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('transportStatus'),
            field: 'transportStatus',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('deliveredAt'),
            field: 'deliveredAt',
            type: DATE_TYPE,
        },
    ]
}

export const firstSectionOfCarInformation = (vehicle) => {
    let dsble = true;

    if (['2','3','4'].includes(vehicle?.typologie)) {
         dsble = false;
    }

    let section = [
        {
            label: i18n.t('vehicleType'),
            field: 'vehicleType',
            type: TEXT_TYPE,
            disabled: true,
            split: 1,
        },
        {
            label: i18n.t('brand'),
            field: 'brand',
            type: TEXT_TYPE,
            disabled: true,
            split: 2,
        },
        {
            label: i18n.t('commercialType'),
            field: 'commercialType',
            type: TEXT_TYPE,
            disabled: dsble,
        },
        {
            label: i18n.t('realMileage'),
            field: 'kilometersAmountReal',
            min: 0,
            type: NUMBER_TYPE,
            required: true,
            specificValidator: (value) => {
                if (value < 0) {
                    return i18n.t(INVALID_INPUT);
                }
            },
            split: 1,
        },
        {
            label: i18n.t('declaredMileage'),
            field: 'declaredMileage',
            type: NUMBER_TYPE,
            disabled: true,
            split: 2,
        },
    ]
    if (['2','3'].includes(vehicle?.typologie)) {
        section = [
            ...section,
            {
                label: i18n.t('nbhr'),
                field: 'nbhr',
                min: 0,
                type: NUMBER_TYPE,
                specificValidator: (value) => {
                    if (value < 0) {
                        return i18n.t(INVALID_INPUT);
                    }
                },
                split: 1,
            },
            {
                label: i18n.t('nbhd'),
                field: 'nbhd',
                type: NUMBER_TYPE,
                disabled: true,
                split: 2,
            },
        ]
    }
    if (vehicle?.typologie === '3') {
        section = [
            ...section,
            {
                label: i18n.t('nbh'),
                field: 'nbh',
                min: 0,
                type: NUMBER_TYPE,
                specificValidator: (value) => {
                    if (value < 0) {
                        return i18n.t(INVALID_INPUT);
                    }
                },
            },
        ]
    }
    section =
        [
            ...section,
            {
                label: i18n.t('color'),
                field: 'color',
                type: CONTROLLED_LIST_AUTOCOMPLETE_TYPE,
                required: true,
                split: 1,
            },
            {
                label: i18n.t('colorType'),
                field: 'colorType',
                type: CONTROLLED_LIST_SELECT_TYPE,
                required: true,
                split: 2,
            },
            {
                label: i18n.t('drivingSchool'),
                field: 'isDrivingSchool',
                type: CHECKBOX_TYPE,
                inRow: true,
            },
            {
                label: i18n.t('taxi'),
                field: 'isTaxi',
                type: CHECKBOX_TYPE,
                inRow: true,
            },
            {
                label: 'VSL',
                field: 'isVsl',
                type: CHECKBOX_TYPE,
                inRow: true,
            },
            {
                label: i18n.t('assessed'),
                field: 'isAssessed',
                type: CHECKBOX_TYPE,
                inRow: true,
            },
        ]
    return section
}

export const secondSectionOfCarInformation = (vehicle) => {
    let section = []
    if (vehicle?.typologie === '3') {
        section = [
            ...section,
            {
                label: 'Regroupement',
                field: 'regroupement',
                type: TEXT_TYPE,
                disabled: true,
            }
        ]
    }

    section = [
        ...section,
        {
            label: i18n.t('serialNumber'),
            field: 'serialNumber',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('mineType'),
            field: 'mineType',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('carBody'),
            field: 'carBody',
            type: TEXT_TYPE,
            disabled: true,
            split: 1,
        },
        {
            label: i18n.t('energy'),
            field: 'energy',
            type: TEXT_TYPE,
            disabled: true,
            split: 2,
        },
    ]

    if (vehicle?.typologie !== '3') {
        section = [
            ...section,
            {
                label: i18n.t('gearbox'),
                field: 'gearbox',
                type: CONTROLLED_LIST_SELECT_TYPE,
                required: true,
            }
        ]
    }
    if (vehicle?.typologie === '2') {
        section = [
            ...section,
            {
                label: i18n.t('ptc'),
                field: 'ptc',
                min: 0,
                type: FLOAT_TYPE,
                specificValidator: (value) => {
                    if (value < 0) {
                        return i18n.t(INVALID_INPUT);
                    }
                },
            },
            {
                label: i18n.t('ptr'),
                field: 'ptr',
                min: 0,
                type: FLOAT_TYPE,
                specificValidator: (value) => {
                    if (value < 0) {
                        return i18n.t(INVALID_INPUT);
                    }
                },
            },
        ]
    }
    return section
}

export const thirdSectionOfCarInformation = (vehicle) => {
    let section = [
        {
            label: i18n.t('power'),
            field: 'power',
            type: TEXT_TYPE,
            disabled: true,
            split: 1,
        },
        {
            label: i18n.t('co2'),
            field: 'co2',
            type: TEXT_TYPE,
            disabled: true,
            split: 2,
        },
        {
            label: i18n.t('registeredAt'),
            field: 'registeredAt',
            type: TEXT_TYPE,
            disabled: true,
            split: 1,
        },
        {
            label: i18n.t('vintage'),
            field: 'anneeMillesime',
            type: TEXT_TYPE,
            disabled: vehicle?.typologie !== '3',
            split: 2,
        },

        {
            label: i18n.t('nonConformite'),
            field: 'nonConformite',
            type: CHECKBOX_TYPE,
            inRow: true,
        },
    ]

    if (vehicle?.typologie !== '3') {
        section = [
            ...section,
            {
                label: i18n.t('doorsNumber'),
                field: 'doorsNumber',
                type: CONTROLLED_LIST_SELECT_TYPE,
                required: vehicle?.typologie !== '3',
                split: 1,
            },
            {
                label: i18n.t('seatsNumber'),
                field: 'seatsNumber',
                min: 1,
                type: NUMBER_TYPE,
                specificValidator: (value) => {
                    if (parseInt(value) < 1) {
                        return i18n.t(INVALID_INPUT);
                    }
                },
                required: vehicle?.typologie !== '3',
                split: 2,
            },
        ]
    }
    if (vehicle?.typologie === '2') {
        section = [
            ...section,
            {
                label: i18n.t('datemines'),
                field: 'datemines',
                type: DATE_TYPE,
            },
            {
                label: i18n.t('certcar'),
                field: 'certcar',
                type: CHECKBOX_TYPE,
            },
            {
                label: i18n.t('br'),
                field: 'br',
                type: CHECKBOX_TYPE,
            },
        ]
    }
    return section
}

const vehicleGeneralFieldsAllInOne = (vehicle) => {
    return flatten([
        firstSectionOfGeneralInformation(vehicle),
        secondSectionOfGeneralInformation(vehicle),
        thirdSectionOfGeneralInformation(vehicle),
        fourthSectionOfGeneralInformation(vehicle),
        firstSectionOfCarInformation(vehicle),
        secondSectionOfCarInformation(vehicle),
        thirdSectionOfCarInformation(vehicle),
    ]);
}

export const toBeValidatedVehicleGeneralFields = (vehicle) => {
    return (
        vehicleGeneralFieldsAllInOne(vehicle).filter(field => {
            if (field.required || field.specificValidator) {
                return field
            }
        })
    )
}