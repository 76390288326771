import CustomCard from '../layout/CustomCard';
import DescriptionIcon from '@material-ui/icons/Description';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormFieldRender, {NUMBER_TYPE, RADIO_TYPE, TEXT_TYPE} from '../layout/FormFieldRender';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import VehicleFormMemo from './VehicleFormMemo';
import {
    firstSectionOfCarInformation,
    firstSectionOfGeneralInformation,
    fourthSectionOfGeneralInformation, secondSectionOfCarInformation,
    secondSectionOfGeneralInformation, thirdSectionOfCarInformation,
    thirdSectionOfGeneralInformation,
} from '../../utils/fields-provider/VehicleGeneralFieldsProvider';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const VehicleFormGeneral = ({
    vehicle,
}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <form className={classes.root}>
            <Grid container spacing={2}>
                <Grid item container>
                    <CustomCard label={t('generalInfo')} icon={<DescriptionIcon/>}>
                        <Grid container>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {firstSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {secondSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {thirdSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {fourthSectionOfGeneralInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container>
                    <CustomCard label={t('carInfo')} icon={<DriveEtaIcon/>}>
                        <Grid container>
                            <Grid item xs={6} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {firstSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            min={field.min}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            specificValidator={field.specificValidator}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    <Grid container item>
                                        <VehicleFormMemo vehicle={vehicle}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {secondSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {thirdSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            min={field.min}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            specificValidator={field.specificValidator}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            {
                ['2','3'].includes(vehicle?.typologie) ?
                    <Grid container>
                        <Grid container item xs={12}>
                            <CustomCard>
                                <Grid container item xs={12} spacing={2}>
                                    {
                                        vehicle?.typologie==='3' ?
                                            <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={{
                                                            fontWeight:'bold',
                                                            fontSize:'1.2em',
                                                            marginTop:'0.3em'
                                                        }}
                                                    >
                                                        Matériel
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} container>
                                                    <FormFieldRender
                                                        label={'Capacité levage maxi'}
                                                        type={NUMBER_TYPE}
                                                        field={'equipementCapaciteLevage'}
                                                        value={vehicle?.equipementCapaciteLevage}
                                                        min={0}
                                                        adornment={'t'}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} container>
                                                    <FormFieldRender
                                                        label={'Hauteur levage maxi'}
                                                        type={NUMBER_TYPE}
                                                        field={'equipementHauteur'}
                                                        value={vehicle?.equipementHauteur}
                                                        min={0}
                                                        adornment={'m'}
                                                    />
                                                </Grid>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        ['2','3'].includes(vehicle?.typologie) ?
                                            <>
                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Pneus avants
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Dimensions'}
                                                            type={TEXT_TYPE}
                                                            field={'pneuDimension'}
                                                            value={vehicle?.pneuDimension}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Usure'}
                                                            type={NUMBER_TYPE}
                                                            field={'pneuMarque'}
                                                            value={vehicle?.pneuMarque}
                                                            min={0}
                                                            max={100}
                                                            adornment={'%'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={'auto'}>
                                                        <FormFieldRender
                                                            type={RADIO_TYPE}
                                                            choices={[
                                                                {label: 'Gonflables', value: '1'},
                                                                {label: 'PPS', value: '2'},
                                                            ]}
                                                            field={'pneusAvant'}
                                                            value={vehicle?.pneusAvant}
                                                        />
                                                    </Grid>
                                                    {
                                                        vehicle?.typologie==='2' ?
                                                            <Grid item xs={2} container>
                                                                <FormFieldRender
                                                                    label={'Empattement'}
                                                                    type={TEXT_TYPE}
                                                                    field={'equipementPlus'}
                                                                    value={vehicle?.equipementPlus}
                                                                />
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid>

                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Pneus arrières
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Dimensions'}
                                                            type={TEXT_TYPE}
                                                            field={'equipementAnnee'}
                                                            value={vehicle?.equipementAnnee}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Usure'}
                                                            type={NUMBER_TYPE}
                                                            field={'equipementHeure'}
                                                            value={vehicle?.equipementHeure}
                                                            min={0}
                                                            max={100}
                                                            adornment={'%'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs container>
                                                        <FormFieldRender
                                                            type={RADIO_TYPE}
                                                            choices={[
                                                                {label: 'Gonflables', value: '1'},
                                                                {label: 'PPS', value: '2'},
                                                            ]}
                                                            field={'pneusArrieres'}
                                                            value={vehicle?.pneusArrieres}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>


                                            : null
                                    }

                                    {
                                        vehicle?.typologie==='3' ?
                                            <>
                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Chenilles
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Patins'}
                                                            type={TEXT_TYPE}
                                                            field={'patins'}
                                                            value={vehicle?.patins}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Usure'}
                                                            type={NUMBER_TYPE}
                                                            field={'usureChenilles'}
                                                            value={vehicle?.usureChenilles}
                                                            min={0}
                                                            max={100}
                                                            adornment={'%'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} container>
                                                        <FormFieldRender
                                                            type={RADIO_TYPE}
                                                            choices={[
                                                                {label: 'Caoutchouc', value: '1'},
                                                                {label: 'Acier', value: '2'},
                                                            ]}
                                                            field={'chenilles'}
                                                            value={vehicle?.chenilles}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Godets
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Quantité'}
                                                            type={TEXT_TYPE}
                                                            field={'quantiteGodets'}
                                                            value={vehicle?.quantiteGodets}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Dimensions'}
                                                            type={TEXT_TYPE}
                                                            field={'dimensionsGodets'}
                                                            value={vehicle?.dimensionsGodets}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>

                                            : null
                                    }

                                    {
                                        vehicle?.typologie==='2' ?
                                            <>
                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Grue auxiliaire
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Marque'}
                                                            type={TEXT_TYPE}
                                                            field={'equipementMarque'}
                                                            value={vehicle?.equipementMarque}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Modèle'}
                                                            type={TEXT_TYPE}
                                                            field={'equipementModele'}
                                                            value={vehicle?.equipementModele}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'N° série'}
                                                            type={TEXT_TYPE}
                                                            field={'equipementNumeroSerie'}
                                                            value={vehicle?.equipementNumeroSerie}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Portée hydraulique'}
                                                            type={TEXT_TYPE}
                                                            field={'dimensionInterieureBenne'}
                                                            value={vehicle?.dimensionInterieureBenne}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Dimensions
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} container>
                                                        <FormFieldRender
                                                            type={RADIO_TYPE}
                                                            choices={[
                                                                {label: 'Caisse', value: '1'},
                                                                {label: 'Benne', value: '2'},
                                                                {label: 'Plateau', value: '3'},
                                                            ]}
                                                            field={'dimensionsCaisseBennePlateau'}
                                                            value={vehicle?.dimensionsCaisseBennePlateau}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Longueur'}
                                                            type={NUMBER_TYPE}
                                                            field={'longueur'}
                                                            value={vehicle?.longueur}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Largeur'}
                                                            type={NUMBER_TYPE}
                                                            field={'largeur'}
                                                            value={vehicle?.largeur}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Hauteur'}
                                                            type={NUMBER_TYPE}
                                                            field={'hauteur'}
                                                            value={vehicle?.hauteur}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={12} spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={{
                                                                fontWeight:'bold',
                                                                fontSize:'1.2em',
                                                                marginTop:'0.3em'
                                                            }}
                                                        >
                                                            Hayon
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Marque'}
                                                            type={TEXT_TYPE}
                                                            field={'marqueHayon'}
                                                            value={vehicle?.marqueHayon}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} container>
                                                        <FormFieldRender
                                                            label={'Capacité'}
                                                            type={TEXT_TYPE}
                                                            field={'hayonCapacite'}
                                                            value={vehicle?.hayonCapacite}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :null
                                    }
                                </Grid>
                            </CustomCard>
                        </Grid>
                    </Grid>
                : null
            }
        </form>
    );
}

VehicleFormGeneral.propTypes = {
    vehicle: PropTypes.object.isRequired,
}

export default VehicleFormGeneral;